const familyMembers = [
  {
    id: 1,
    name: 'Gustavo',
    role: 'Dad',
    age: 38,
    sport: 'Football',
    phrase: 'Besitame o me muero',
    image: '../assets/tavo.png',
  },
  {
    id: 2,
    name: 'Paulina',    
    role: 'Mom',
    sport: 'Running',
    phrase: 'no, no, no, si, no',
    age: 39,
    image: './assets/pau.png'
  },
  {
    id: 3,
    name: 'Sofia',
    role: 'Daughter',
    phrase: 'último, último please',
    sport: 'Scooter',
    age: 4,
    image: './assets/sofia.png'
  },
  {
    id: 4,
    name: 'Blanca',
    role: 'pet',
    phrase: 'miaaaaauuuuu',
    sport: 'Hide & Seek',
    age: 2,
    image: '../assets/blanca.png'
  }
]

export default familyMembers;